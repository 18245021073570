/*------------------------------------
	Fonts
------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap");
/*------------------------------------
	Preloader
------------------------------------*/
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 153, 0, 0.75) 1.5em 0 0 0, rgba(255, 153, 0, 0.75) 1.1em 1.1em 0 0, rgba(255, 153, 0, 0.75) 0 1.5em 0 0, rgba(255, 153, 0, 0.75) -1.1em 1.1em 0 0, rgba(255, 153, 0, 0.75) -1.5em 0 0 0, rgba(255, 153, 0, 0.75) -1.1em -1.1em 0 0, rgba(255, 153, 0, 0.75) 0 -1.5em 0 0, rgba(255, 153, 0, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255, 153, 0, 0.75) 1.5em 0 0 0, rgba(255, 153, 0, 0.75) 1.1em 1.1em 0 0, rgba(255, 153, 0, 0.75) 0 1.5em 0 0, rgba(255, 153, 0, 0.75) -1.1em 1.1em 0 0, rgba(255, 153, 0, 0.75) -1.5em 0 0 0, rgba(255, 153, 0, 0.75) -1.1em -1.1em 0 0, rgba(255, 153, 0, 0.75) 0 -1.5em 0 0, rgba(255, 153, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.product-img .rounded {
  border-radius: 10%;
}

b {
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
}
blockquote {
  font-family: "Times New Roman", Times, serif;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.dash_user_footer ul {
  padding: 0;
  margin: 2rem 0 0;
  display: flex;
  width: 100%;
  background: #017efa;
}
.dash_user_footer ul li {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.333333%;
  flex: 0 0 33.333333%;
  border-right: 1px solid #1a8cfc;
}
.dash_user_footer ul li:last-child {
  border: none;
}
.dash_user_footer ul li a {
  font-size: 16px;
  padding: 14px 0;
  color: #ffffff;
}

.vpage-title {
  padding: 1rem 0;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-size: cover !important;
  border-radius: 12px;
}
.vpage-title .ipt-title {
  font-size: 50px;
  margin-bottom: 15px;
  color: #ffffff;
}
.vpage-title .ipn-subtitle {
  color: #199dff;
  font-weight: 500;
}
.veshm-dash-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  height: 160px;
  background-color: #444;
  border-radius: 4px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  transition: 0.3s;
  cursor: default;
}

.veshm-dash-wrapper.widget-1 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ffffff;
  border-left: 4px solid #2bb170;
}
.veshm-dash-wrapper.widget-2 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ffffff;
  border-left: 4px solid #ce024b;
}
.veshm-dash-wrapper.widget-3 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ffffff;
  border-left: 4px solid #f39f00;
}

.veshm-dash-icon {
  margin-left: 10px;
  position: relative;
}

.vsm-icon-lio {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 25px;
}
.veshm-dash-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.6rem;
}
.veshm-dash-block_content h4 {
  font-size: 25px;
  font-weight: 600;
  padding: 0;
  margin: 0 0 5px;
  letter-spacing: 1px;
  display: flex;
  align-items: flex-start;
}
.veshm-dash-block_content h6 {
  line-height: 1;
  margin: 0;
  color: #6d7f8e;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}
.veshm-dash-block_content span {
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  line-height: 2;
  margin-left: 5px;
}
.veshm-dash-block-icon {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-40%);
  font-size: 80px;
  opacity: 0.2;
}
.veshm-dash-block-icon {
  font-size: 65px;
}
.veshm-dash-wrapper.widget-1 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ffffff;
  border-left: 4px solid #2bb170;
}
.veshm-dash-wrapper.widget-2 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ffffff;
  border-left: 4px solid #ce024b;
}
.veshm-dash-wrapper.widget-3 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ffffff;
  border-left: 4px solid #f39f00;
}
.dashboard_property {
  background: #ffffff;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgb(62 28 131 / 10%);
  -webkit-box-shadow: 0 0 10px 0 rgb(62 28 131 / 10%);
}
.dashboard_property .table .thead-dark th {
  color: #fff;
  background-color: #27ae60;
  border-color: #27ae60;
}
.dash_prt_wrap {
  display: flex;
  align-items: center;
  width: auto;
}
.dash_prt_thumb {
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
  height: 80px;
}
.dash_prt_thumb img {
  background-size: cover;
  object-fit: cover;
  height: 100%;
}
.dash_prt_caption {
  flex: 1;
  padding-left: 10px;
}
.dash_prt_caption h5 {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
}
.prt_dashb_lot {
  font-size: 13px;
  color: #667094;
}
.prt_dash_rate span {
  font-size: 14px;
  font-weight: 700;
  color: #333c56;
}
.prt_leads span {
  font-size: 13px;
  color: #667094;
}
.prt_leads_list ul {
  padding: 0;
  padding: 0;
}
.prt_leads_list ul li {
  list-style: none;
  display: inline-block;
}
.prt_leads_list ul li a {
  width: 30px;
  height: 30px;
  display: list-item;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  line-height: 30px;
  border-radius: 50%;
}

.prt_leads_list ul li a {
  position: relative;
  margin-left: -15px;
  border: 2px solid #ffffff;
  z-index: 1;
}
.prt_leads_list ul li:first-child a {
  margin: 0;
}
.prt_leads_list ul li a._leads_name {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f4f5f7;
  line-height: 30px;
  border-radius: 50%;
  font-weight: 600;
}
.prt_leads_list ul li a.leades_more {
  width: 30px;
  height: 30px;
  display: list-item;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  background: #edf0f5;
  line-height: 30px;
  font-size: 13px;
  border-radius: 50%;
  font-weight: 600;
  color: #6a728e;
}
._leads_view_title span {
  font-size: 13px;
  color: #6b7494;
}
._leads_view h5,
._leads_posted h5 {
  position: relative;
  margin: 0;
  line-height: 1;
  font-size: 15px;
}
._leads_status .active {
  padding: 3px 15px;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 50px;
  font-size: 12px;
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.2);
  font-weight: 600;
  margin-bottom: 3px;
  display: inline-flex;
}
._leads_status .expire {
  padding: 3px 15px;
  background: rgba(233, 30, 58, 0.1);
  border-radius: 50px;
  font-size: 12px;
  color: #e91e3a;
  border: 1px solid rgba(233, 30, 58, 0.2);
  font-weight: 600;
  margin-bottom: 3px;
  display: inline-flex;
}
._leads_action a {
  width: 37px;
  height: 37px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dde0e8;
  font-size: 14px;
  border-radius: 4px;
  color: #828bab;
  margin: 2px;
}
._leads_action a:hover {
  width: 37px;
  height: 37px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dde0e8;
  font-size: 14px;
  border-radius: 4px;
  background: #003a75;
  color: #ffffff;
  margin: 2px;
}
.prt_leads_list ul li a._leads_name.style-1 {
  background: rgba(255, 118, 0, 0.1);
  color: #ff7600;
}
.prt_leads_list ul li a._leads_name.style-2 {
  background: rgba(63, 81, 181, 0.1);
  color: #3f51b5;
}
.prt_leads_list ul li a._leads_name.style-3 {
  background: rgba(139, 195, 74, 0.1);
  color: #8bc34a;
}
.prt_leads_list ul li a._leads_name.style-4 {
  background: rgba(3, 169, 244, 0.1);
  color: #03a9f4;
}
.prt_leads_list ul li a._leads_name.style-5 {
  background: rgba(178, 66, 197, 0.1);
  color: #b242c5;
}
.prt_leads_list ul li a._leads_name.style-6 {
  background: rgba(0, 150, 136, 0.1);
  color: #009688;
}
.prt_leads_list ul li a._leads_name.style-7 {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}
.prt_leads_list ul li a._leads_name.style-8 {
  background: rgba(125, 89, 189, 0.1);
  color: #7d59bd;
}
.dash_user_avater {
  text-align: center;
  margin: 0.1em 0 0.1rem;
  padding: 0 1rem;
}
.dash_user_avater img {
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 8px;
  box-shadow: 0px 0px 0px 5px rgb(237 240 245);
  -webkit-box-shadow: 0px 0px 0px 5px rgb(237 240 245);
}
.dash_user_avater h4 {
  margin: 10px 0 0px 0;
  font-size: 17px;
}
._prt_filt_add_new .prt_submit_link {
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: #017efa;
  border-radius: 0.2rem;
}
._prt_filt_add_new .prt_submit_link i {
  color: #87c4ff;
  margin-right: 7px;
}

._leads_status .active {
  padding: 3px 15px;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 50px;
  font-size: 12px;
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.2);
  font-weight: 600;
  margin-bottom: 3px;
  display: inline-flex;
}
._leads_status .expire {
  padding: 3px 15px;
  background: rgba(233, 30, 58, 0.1);
  border-radius: 50px;
  font-size: 12px;
  color: #e91e3a;
  border: 1px solid rgba(233, 30, 58, 0.2);
  font-weight: 600;
  margin-bottom: 3px;
  display: inline-flex;
}

span._user_status {
  width: 12px;
  height: 12px;
  background: #252629;
  display: flex;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: absolute;
  right: -5px;
  top: 50%;
}
span._user_status.online {
  background: #28af51;
}
span._user_status.offline {
  background: #e23528;
}
span._user_status.busy {
  background: #ff9800;
}
/*-------------------- Messages Design ----------------*/
.messages-container {
  margin: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;
}
.messages-headline {
  font-size: 16px;
  margin: 0;
  padding: 22px 30px;
  background-color: #fff;
  display: block;
  border-bottom: 1px solid #edf1f9;
  border-radius: 4px 4px 0 0;
  position: relative;
}
.messages-headline h4 {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}
.message-action {
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  transition: 0.3s;
  padding: 0;
  float: right;
}
.messages-container-inner {
  display: flex;
}
.messages-container-inner .dash-msg-inbox {
  max-width: 340px;
  border-right: 1px solid #edf1f9;
}
.messages-container-inner .dash-msg-inbox {
  overflow: hidden;
}
.messages-container-inner .dash-msg-content {
  padding: 30px;
}
.messages-container-inner .dash-msg-inbox,
.messages-container-inner .dash-msg-content {
  flex: 1;
}
.messages-container-inner .dash-msg-inbox ul {
  max-height: 950px;
  overflow-y: scroll;
  width: 357px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dash-msg-inbox ul li.active-message {
  border-left: 3px solid #166ef3;
}
.dash-msg-inbox ul li {
  border-bottom: 1px solid #edf1f9;
  transition: 0.2s;
  list-style: none;
}
.dash-msg-inbox ul li:last-child {
  border-bottom: none;
}
.message-by-headline h5 {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1;
}
.dash-msg-inbox ul li a {
  position: relative;
  display: block;
  padding: 30px;
}
.messages-container-inner .dash-msg-inbox ul li a {
  padding: 25px;
}

.dash-msg-inbox .dash-msg-avatar {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.messages-container-inner .dash-msg-inbox .dash-msg-avatar {
  left: 25px;
}

.dash-msg-avatar img {
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
.messages-container-inner .dash-msg-avatar img {
  width: 40px;
  height: 40px;
}

.dash-msg-inbox .message-by {
  margin-left: 85px;
}
.messages-container-inner .dash-msg-inbox .message-by {
  margin-left: 50px;
}
.messages-container-inner .message-by-headline h5 i {
  background: #f98f14;
  padding: 3px 8px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 13px;
}
.messages-container-inner .message-by-headline span {
  font-size: 13px;
  position: absolute;
  top: 25px;
  right: 25px;
  float: right;
  color: #888;
}
.messages-container-inner .dash-msg-inbox .message-by p {
  height: 26px;
  max-width: 205px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #6b747d;
  line-height: 27px;
}
.message-plunch {
  display: block;
  position: relative;
  margin-bottom: 25px;
}
.message-plunch .dash-msg-avatar {
  position: absolute;
  left: 0;
  top: 0;
}
.message-plunch .dash-msg-avatar img {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
}
.message-plunch .dash-msg-text {
  margin-left: 70px;
  background-color: #f4f5f7;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}
.message-plunch .dash-msg-text:before {
  position: absolute;
  top: 12px;
  left: -9px;
  content: "\f0d9";
  font-family: fontawesome;
  font-size: 31px;
  color: #f4f5f7;
}
.message-plunch .dash-msg-text p {
  font-size: 15px;
  padding: 0;
  margin: 0;
  line-height: 27px;
}
.message-plunch.me .dash-msg-avatar {
  left: auto;
  right: 0;
  position: absolute;
  top: 0;
}
.message-plunch.me .dash-msg-text {
  color: #39b36e;
  background-color: rgba(57, 179, 110, 0.1);
}
.message-plunch.me .dash-msg-text {
  margin-left: 0;
  margin-right: 70px;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}
.message-plunch.me .dash-msg-text:before {
  color: rgb(235 247 240);
}
.message-plunch.me .dash-msg-text:before {
  left: auto;
  right: -11px;
  content: "\f0da";
}
.message-reply {
  display: block;
  margin-top: 15px;
}
.message-reply textarea {
  max-width: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  padding: 15px 20px;
}
.message-reply button {
  border-radius: 4px;
  margin-top: 5px;
}
span._user_status {
  width: 12px;
  height: 12px;
  background: #252629;
  display: flex;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: absolute;
  right: -5px;
  top: 50%;
}
span._user_status.online {
  background: #28af51;
}
span._user_status.offline {
  background: #e23528;
}
span._user_status.busy {
  background: #ff9800;
}

.lock-screen {
  display: flex;
  z-index: 999;
  background: -webkit-linear-gradient(
      rgba(26, 0, 0, 0.8),
      rgba(204, 139, 1, 0.5)
    ),
    url(./assets/img/welcome.gif);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  backdrop-filter: blur(10px);
}
.div-center{
  width: 350px;
  margin: auto;
}

.vesh-detail-bloc-group {
  display: block;
  position: relative;
  width: 100%;
}
.vesh-detail-bloc {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  background: #ffffff;
  padding: 0;
  border-radius: 0.4rem;
}
.vesh-detail-bloc .vesh-detail-bloc_header {
  padding: 1rem 1.5rem;
  border: none;
}
.vesh-detail-bloc .vesh-detail-bloc_header h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
}
.vesh-detail-bloc .vesh-detail-bloc-body {
  padding: 0rem 1.5rem 1.5rem;
  display: inline-block;
  width: 100%;
}
.vesh-detail-bloc.style_2 {
  border-bottom: 1px solid #ebeef0;
  border-radius: 0;
}
.vesh-detail-bloc.style_2 .vesh-detail-bloc_header {
  padding: 1rem 0;
}
.vesh-detail-bloc.style_2 .vesh-detail-bloc-body {
  padding: 0 0 1rem;
}
.vesh-detail-bloc-group .vesh-detail-bloc:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.vesh-detail-bloc-group .vesh-detail-bloc.style_2:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.vesh-detail-bloc-group
  .vesh-detail-bloc.style_2:last-child
  .vesh-detail-bloc-body {
  padding: 0;
}
.vesh-detail-bloc-body p:last-child {
  margin-bottom: 0;
}
.vesh-detail-bloc .vesh-detail-bloc_header h4:after {
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  position: relative;
  float: right;
  margin-right: 0px;
  transition: 0.2s ease-out;
  background: rgba(1, 126, 250, 0.1);
  border: 1px solid rgba(1, 126, 250, 0.2);
  color: #017efa;
  width: 26px;
  height: 26px;
  font-weight: 600;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vesh-detail-bloc .vesh-detail-bloc_header .collapsed h4:after {
  transform: rotate(180deg);
}
.vesh-detail-bloc.style_2 .vesh-detail-bloc_header h4.no-arrow:after,
.vesh-detail-bloc .vesh-detail-bloc_header h4.no-arrow:after {
  display: none;
}
.vesh-detail-headup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 100%;
}
.vesh-detail-headup-first {
  padding: 0 1rem 0 0rem;
}
.prt-detail-title-desc {
  display: block;
  position: relative;
}
.prt-detail-title-desc h4 {
  margin-bottom: 2px;
}
.prt-price-fix {
  display: block;
  margin: 0;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
}
.prt-price-fix span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #687b96;
}
.deatil_adv_features {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.ilio-icon-wrap {
  list-style: none;
  width: 100%;
  height: 100px;
  border-radius: 0.2rem;
  background: #f3f7fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ilio-icon {
  font-size: 30px;
  color: #1b5086;
}
.ilio-text {
  font-size: 13px;
  font-weight: 500;
  color: #294459;
}
.avl-features {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.avl-features li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 10px 0 10px;
  padding-left: 30px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  color: #617896;
  font-size: 13px;
  font-weight: 500;
}
.avl-features li:before {
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  color: #00ba74;
  width: 20px;
  height: 20px;
  background: rgba(0, 186, 116, 0.1);
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  left: 0;
}

.lvs-caption {
  color: #617896;
  font-size: 15px;
  font-weight: 500;
}
.lvs-caption i {
  color: #017efa;
  margin-right: 10px;
}
.like_share_list {
  padding: 0;
  margin: 0;
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: space-between;
}
.like_share_list li {
  flex: 0 0 33.333333%;
  width: 33.333333%;
  list-style: none;
  display: block;
  padding: 0 0.5rem;
}
.like_share_list li .btn {
  width: 100%;
}
.like_share_list .btn {
  font-weight: 500;
}
.mortgage-wrp-bloc {
  position: relative;
  width: 100%;
  background: rgb(0 190 130 / 10%);
  border: 1px solid rgb(0 190 130 / 20%);
  border-radius: 0.4rem;
  display: flex;
  flex-wrap: wrap;
}
.mortgage-wrp-bloc-single {
  width: 25%;
  flex: 0 0 25%;
  padding: 1.2rem 1.5rem;
  border-right: 1px dashed rgb(0 190 130 / 40%);
}
.mortgage-wrp-bloc-single:last-child {
  border-right: none;
}
.mortgage-wrp-bloc-single .mrtg-wrp-title {
  font-size: 13px;
  font-weight: 500;
  color: #154537;
  margin-bottom: 3px;
}
.mortgage-wrp-bloc-single .mrtg-wrp-subtitle {
  font-size: 24px;
  font-weight: 800;
  color: #00a372;
}
.pg-side-groups {
  display: block;
  position: relative;
  width: 100%;
}
.pg-side-block {
  position: relative;
  display: block;
  background: #ffffff;
  border-radius: 0.4rem;
  margin-bottom: 20px;
}
.pg-side-block:last-child {
  margin: 0;
}
.pg-side-block-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem;
  border-bottom: 2px solid #f3f7fa;
}
.pg-side-left {
  margin-right: 15px;
}
.pg-side-thumb {
  width: 80px;
  border: 4px solid #f3f7fa;
  border-radius: 50%;
}
.pg-side-right-caption h4 {
  font-size: 20px;
  margin: 0;
}
.pg-side-right-caption span {
  font-size: 13px;
  font-weight: 500;
  color: #617896;
}
.pg-side-block-info {
  position: relative;
  display: block;
  padding: 1.5rem 1.5rem 1rem;
}
.vl-elfo-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}
.vl-elfo-group:last-child {
  margin: 0;
}
.vl-elfo-icon {
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #2d5272;
  margin-right: 7px;
  border: 2px solid #f3f7fa;
}
.vl-elfo-caption {
  display: block;
  position: relative;
  width: auto;
}
.vl-elfo-caption h6 {
  font-size: 14px;
  margin: 0;
}
.vl-elfo-caption p {
  margin: 0;
  font-size: 13px;
  color: #617896;
  font-weight: 500;
}
.pg-side-block-buttons {
  position: relative;
  display: block;
  padding: 0 1.5rem 1.5rem;
}
.single-button {
  display: block;
  margin: 10px 0;
}
.booking-info-try {
  position: relative;
  display: block;
  padding: 1rem;
}
.booking-info-try ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.booking-info-try ul li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
}
.booking-info-try ul li h6 {
  font-size: 12px;
  margin: 0;
  line-height: 1;
}
.booking-info-try ul li span {
  font-size: 12px;
  font-weight: 600;
  color: #048e5a;
}
.booking-info-try ul li:last-child {
  background: #1ec08f;
  padding: 0.5rem;
  border-radius: 0.2rem;
}
.booking-info-try ul li h6.total-title {
  font-size: 14px;
  color: #ffffff;
}
.booking-info-try ul li span.total-price {
  font-size: 13px;
  color: #ffffff;
}
.datedrp {
  --dd-overlay: rgba(0, 0, 0, 0.75);
  --dd-text1: #091e33;
  --dd-primary: #617896;
  --dd-gradient: linear-gradient(45deg, #00be82 0%, #00be82 100%);
  --dd-selectedBackground: #00be82;
  --dd-monthBackground: #017efa;
  --dd-confirmButtonBackground: #017efa;
}
.label {
  padding: 4px 15px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #1dc8cd;
}

.label-success {
  background-color: #00be82;
  font-size: 17px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.label-info {
  background-color: #017efa;
}

.label-warning {
  background-color: #ffac45;
}

.label-danger {
  background-color: #e55151;
}

.label-megna {
  background-color: #1dc8cd;
}

.label-primary {
  background-color: #017efa;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #3e4555;
}

.label-default {
  background-color: #f4f8fa;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: rgba(0, 190, 130, 0.1);
  color: #00be82;
}

.label-light-info {
  background-color: rgba(1, 126, 250, 0.1);
  color: #017efa;
}

.label-light-warning {
  background-color: rgba(255, 172, 69, 0.1);
  color: #ffac45;
}

.label-light-danger {
  background-color: rgba(229, 81, 81, 0.1);
  color: #e55151;
}

.label-light-megna {
  background-color: rgba(29, 200, 205, 0.1);
  color: #1dc8cd;
}

.label-light-primary {
  background-color: rgba(1, 126, 250, 0.1);
  color: #017efa;
}

.label-light-inverse {
  background-color: rgba(62, 69, 85, 0.1);
  color: #3e4555;
}
img{
  object-fit: cover;
}

/*---------- Notification -----------*/
.card {
  border: 1px solid #e8ebef;
  margin-bottom: 30px;
  border-radius: 0.5rem;
  overflow: hidden;
  background: #ffffff;
}
.ground-list {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ground {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ground-single-list {
  -webkit-box-align: center;
  align-items: center;
}
.ground-single-list {
  padding: 10px 10px;
  border-bottom: 1px solid #eaeff5;
}
.ground-single-list:last-child {
  border: none;
}
.ground > * {
  margin: 0 6px;
}
.ground-single-list a {
  position: relative;
}
.btn-circle-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}
.ground-content {
  -ms-flex: 1;
  flex: 1;
}
.ground-content > * {
  margin-bottom: 0;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.ground-content > * {
  margin-bottom: 0;
  font-weight: 400;
}
.ground-content h6 {
  font-size: 14px;
}
.ground-client-list {
  display: block;
  position: relative;
  width: 100%;
}
.ground-client-single-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #e4ecef;
}
.ground-client-first {
  display: flex;
  float: left;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.ground-client-thumb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.ground-client-caption {
  position: relative;
  padding-left: 0.5rem;
  display: block;
}
.ground-client-caption h5 {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  line-height: 1;
}
.ground-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ground-btn .btns {
  width: 35px;
  height: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
td.prt-fgi {
  color: #022f5d;
  font-weight: 600;
}