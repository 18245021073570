.page-link {
  position: relative;
  display: inline-flex;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  padding: 10px 15px;
  color: #0d6efd;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  cursor: pointer;
}

.page-link:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.page-link:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.page-link:not(:first-child) {
  margin-left: -1px;
}

.page-link:hover,
.page-link:focus {
  color: #0a58ca;
  background-color: #e9ecef;
}

.page-link:focus {
  z-index: 3;
}

.page-link.active {
  z-index: 2;
  color: #ffffff;
  border-color: #0d6efd;
  background-color: #0d6efd;
}

.page-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
